// グローバルに使いたいモジュール,関数はこちらで定義
// 残りはcomponents配下に配置してバンドルする方向性

$(function () {
  $("#Nav .Global>li ul").each(function () {
    $parent = $(this).parent("li");
    $(">a", $parent).append('<span class="SubNavOpen"></span>');
  })
  $(document).on("click", ".SubNavOpen", function () {
    // $(this).parent("a").next().slideToggle(1000);
    $(this).parent("a").next().toggleClass("Active");
    console.log($(this).parent("a").next().hasClass("Active"));
    if ($(this).parent("a").next().hasClass("Active")) {
      $(this).parent("a").next().css({
        "max-height": ($(this).parent("a").next().find("li").length + 1) * $(this).parent("a").outerHeight()
      });
    } else {
      $(this).parent("a").next().removeAttr('style');
    }
    $(this).toggleClass("Active");
    // console.log($(this).parent("a").next().find("li").length);
    return false;
  })
  $(document).on("click", ".NavOpen", function () {
    $("#Nav").addClass("Active");
  });
  $(document).on("click", ".NavClose", function () {
    $("#Nav").removeClass("Active");
  });

  if ($(window).width() <= 786) {
    $("body").css({
      "padding-top": $("#Header").outerHeight(),
      "padding-bottom": $("#FooterFixedNavSP").outerHeight()
    })
  }
})

const moment = require('moment');
import owlCarousel from "owl.carousel";
/**
 * 単純なサンプル
 * moment.jsを使って現在日付をconsole出力するだけ
 */
$(function () {
    $('.TopPage--MainImage .owl-carousel').owlCarousel({
        loop: true,
        margin: 0,
        nav: false,
        items: 1,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: false,
        animateOut: 'fadeOut'
    })
    $('.TopPage--Banner.owl-carousel').owlCarousel({
        loop: true,
        nav: false,
        dots: true,
        items: 3,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: false,
        autoWidth: true,
        center: true,
        responsive: {
            0: {
                margin: 20
            },
            769: {
                margin: 50,
            }
        }
    })
});
